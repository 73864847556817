import { i18n } from '@/i18n';
import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment-timezone';

export const ManifestHeaders: TableHeaders[] = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'invoiceDate',
    label: `${i18n.tc('mainTable.inv')} ${i18n.tc('common.date')}`,
    sortable: true,
    disabled: true,
    formatter: getDay
  },
  {
    key: 'statusLabel',
    label: i18n.tc('mainTable.status'),
    sortable: true
  },
  {
    key: 'driverId',
    label: i18n.tc('common.driver'),
    sortable: true
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'originLocation',
    label: i18n.tc('mainTable.origin'),
    sortable: true
  },
  {
    key: 'destinationLocation',
    label: i18n.tc('mainTable.destination'),
    sortable: true
  },
  {
    key: 'returnLocation',
    label: i18n.tc('order.return'),
    sortable: true
  },
  {
    key: 'accountCode',
    label: i18n.tc('mainTable.acctCode'),
    sortable: true
  },
  {
    key: 'accountCodeName',
    label: i18n.tc('mainTable.acctCodeName'),
    sortable: true
  },
  {
    key: 'description',
    label: i18n.tc('mainTable.desc'),
    sortable: true
  },
  {
    key: 'amount',
    label: i18n.tc('order.rate'),
    sortable: true
  },
  {
    key: 'pod',
    label: i18n.tc('mainTable.pod'),
    sortable: true,
    content: {
      type: CellContentType.Icon,
      label: 'Click to open POD file',
      icon: 'file-earmark',
      isDisabledBy: 'podUploaded'
    }
  }
];

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}
