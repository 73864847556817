

































































import { Component, Vue } from 'vue-property-decorator';

import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';

import SelectionFilter from '@/shared/components/filters/SelectionFilter.vue';
import { DateType } from '@/pages/Accounting/models/filters/date-filter.model';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import DateFilter from '@/shared/components/filters/DateFilter.vue';
import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import { DriverModule } from '@/store';
import { PropertyType } from '@/pages/Main/filters/PropertyFilter/property-filter.model';
import DriverFilter from '@/shared/components/filters/DriverFilter.vue';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { DriverTypes } from '../../models/driver.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import { statusModel } from '../models/filters.model';

@Component({
  components: { SelectionFilter, DIVFilter, DateFilter, DriverFilter, Loading }
})
export default class ManifestFilters extends Vue {
  masterDataService = MasterDataService;
  loading = false;
  status = 'status';
  statusTypes = {
    list: [{ value: 'status', text: 'Status' }],
    autocompleteList: statusModel
  };

  driverTypes = {
    list: [{ value: PropertyType.Driver, text: 'Driver' }],
    autocompleteList: []
  };

  statusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'status'
  });

  driverFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: PropertyType.Driver
  });

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.InvoiceDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.InvoiceDate
  });

  excelData: any;

  constructor() {
    super();
  }

  created() {
    this.search();
  }

  search() {
    DriverModule.setFilters(this.getFilters());
    this.$emit('search');
  }

  async excel() {
    try {
      this.loading = true;
      DriverModule.setFilters(this.getFilters());
      await DriverModule.excel(DriverTypes[this.$route.params.type]);
      if (DriverModule.excelData) {
        window.open(DriverModule.excelData.url);
      }
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
    }
  }

  resetFilters() {
    (this.$refs as any).driverFilter.resetFilter();
    this.statusFilter.search = null;
    this.driverFilter.search = null;
    this.fromFilter.search = moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT);
    this.toFilter.search = moment().format(BS_DATEPICKER_FORMAT);

    DriverModule.setFilters(
      TransformFiltersToJson([
        this.statusFilter,
        this.driverFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
  }

  selectTypeadhead(val) {
    this.driverFilter.search = val?.id;
  }

  private getFilters(): string {
    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    return TransformFiltersToJson([
      this.statusFilter,
      this.driverFilter,
      this.toFilter,
      this.fromFilter
    ]);
  }
}
