import { i18n } from '@/i18n';
import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const BillHeaders: TableHeaders[] = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'orderNumber',
    label: i18n.tc('mainTable.wo'),
    sortable: true
  },
  {
    key: 'containerNumber',
    label: i18n.tc('order.cont'),
    sortable: true
  },
  {
    key: 'driverId',
    label: i18n.tc('common.driver'),
    sortable: true
  },
  {
    key: 'originLocation',
    label: i18n.tc('mainTable.origin'),
    sortable: true
  },
  {
    key: 'destinationLocation',
    label: i18n.tc('mainTable.destination'),
    sortable: true
  },
  {
    key: 'returnLocation',
    label: i18n.tc('order.return'),
    sortable: true
  },
  {
    key: 'checked',
    label: ``,
    sortable: false,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'accountCode',
    label: i18n.tc('mainTable.acctCode'),
    sortable: true
  },
  {
    key: 'accountCodeName',
    label: i18n.tc('mainTable.acctCodeName'),
    sortable: true
  },
  {
    key: 'amount',
    label: i18n.tc('order.amt'),
    sortable: true
  },
  {
    key: 'description',
    label: i18n.tc('mainTable.desc'),
    sortable: true
  },
  {
    key: 'pod',
    label: i18n.tc('mainTable.pod'),
    sortable: true,
    content: {
      type: CellContentType.Icon,
      label: 'Click to open POD file',
      icon: 'file-earmark',
      isDisabledBy: 'podUploaded'
    }
  },
  {
    key: 'reject',
    label: i18n.tc('mainTable.reject'),
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Reject'
    }
  },
  {
    key: 'approval',
    label: i18n.tc('mainTable.approval'),
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Approval'
    }
  }
];
