import { i18n } from '@/i18n';
import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const PodHeaders: TableHeaders[] = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'orderNumber',
    label: i18n.tc('mainTable.wo'),
    sortable: true
  },
  {
    key: 'containerNumber',
    label: i18n.tc('order.cont'),
    sortable: true
  },
  {
    key: 'driverId',
    label: i18n.tc('common.driver'),
    sortable: true
  },
  {
    key: 'fromLocationId',
    label: `${i18n.tc('order.delivery')} ${i18n.tc('common.from')}`,
    sortable: true
  },
  {
    key: 'toLocationId',
    label: `${i18n.tc('order.delivery')} ${i18n.tc('common.from')}`,
    sortable: true
  },
  {
    key: 'deliveryDate',
    label: `${i18n.tc('mainTable.dilv')} ${i18n.tc('common.date')}`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'notice',
    label: i18n.tc('mainTable.notice'),
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Text'
    }
  }
];
