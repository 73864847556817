




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';

@Component({
  components: { Fragment }
})
export default class DriverFilter extends Vue {
  @Prop() filter: FilterInput<string>;
  @Prop() selectionTypesList: [{ value: string; text: string }];
  @Prop() autocompleteFieldsList: string[];

  search: string = null;
  selectedType = null;
  isAutocompleteField = true;
  masterDataService = MasterDataService;

  constructor() {
    super();
  }

  created() {
    this.selectedType = this.selectionTypesList[0].value;
  }

  selectTypeadhead(val) {
    this.filter.search = val?.id;
  }

  onInputValue(val) {
    this.filter.search = val;
  }

  searchPress() {
    this.$emit('searchPress');
  }

  resetFilter() {
    this.filter.search = null;
    this.search = null;
  }
}
